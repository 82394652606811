import React, { memo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { StructuredText } from 'components/cms';
import type { StructuredTextDocument } from 'react-datocms';
import { ResponsiveGrid } from './Layout';

type StyleProps = {
  backgroundColour: string;
  textColour: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: (props: StyleProps) =>
      `${props?.backgroundColour ? props.backgroundColour : theme.palette.Vegemite.main}`,
    '&>*': {
      color: (props: StyleProps) => `${props?.textColour ? props.textColour : theme.palette.Pavlova.main}`,
    },
    '&>ul': {
      margin: '0',
      '&>li p': {
        margin: '0',
      },
    },
  },
  internal: {
    padding: '4px 0 24px 0',
  },
  footerSection: {
    margin: '1.25rem 0 0 0',
    fontSize: '13px',
  },
}));

type Props = {
  content: Array<StructuredTextDocument>;
  backgroundColour?: string;
  textColour?: string;
};

const Component = ({ content, backgroundColour, textColour }: Props) => {
  const classes = useStyles({ backgroundColour, textColour });

  return (
    <div className={classes.root}>
      <ResponsiveGrid>
        <Grid item xs={12}>
          <div className={classes.internal}>
            {content.map((c, i) => (
              <StructuredText key={i} data={c} pClassName={classes.footerSection} gtmEventCategory="Footer" />
            ))}
          </div>
        </Grid>
      </ResponsiveGrid>
    </div>
  );
};

export const Footer = memo(Component);
