import { useFeatureFlags } from 'context';
import { useGetExpertAvailabilitySummary } from 'features/expert-availability/hooks';
import { isSupported } from 'twilio-video';

export type canvasAvailability =
  | 'evaluating' // working it out
  | 'disabled' // feature flag off
  | 'busy' // operator oline but not available
  | 'offline' // out of hours or noone online
  | 'available'; // ok

export const useCanvasAvailability: () => { isSupported: boolean; availability: canvasAvailability } = () => {
  const { userPresenceSummary } = useGetExpertAvailabilitySummary(true);
  const { canvas: canvasEnabled, initialised } = useFeatureFlags();

  if (!userPresenceSummary || !initialised) {
    return {
      isSupported,
      availability: 'evaluating',
    };
  }
  if (!canvasEnabled) {
    return {
      isSupported,
      availability: 'disabled',
    };
  }
  if (userPresenceSummary) {
    if (userPresenceSummary.isExpertOnline) {
      return {
        isSupported,
        availability: 'available',
      };
    } else if (userPresenceSummary.isExpertBusy) {
      return {
        isSupported,
        availability: 'busy',
      };
    } else {
      return {
        isSupported,
        availability: 'offline',
      };
    }
  }
};
