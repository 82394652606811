import { useEffect, useState } from 'react';

export const useTimedStateTransition = <T>(
  states: Array<[stateName: T, duration?: number]>,
  started?: boolean
): { state: T; start: () => void } => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(started ? 0 : null);

  const startFunction = () => {
    setCurrentIndex(0);
  };

  useEffect(() => {
    if (currentIndex !== null) {
      if (currentIndex < states.length - 1) {
        const duration = states[currentIndex][1];
        if (duration === 0) {
          setCurrentIndex(currentIndex + 1);
        } else if (duration) {
          const timeoutHandle = setTimeout(() => {
            setCurrentIndex(currentIndex + 1);
          }, duration);
          return () => {
            clearTimeout(timeoutHandle);
          };
        }
      }
    }
  }, [currentIndex, states]);

  return { state: currentIndex ? states[currentIndex][0] : states[0][0], start: startFunction };
};
