import { Button, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { images } from 'features/home/urls';
import { Link } from 'components';
import { gtmTagPropsToDataset } from 'utils/gtm-tag-utils';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    height: '48px',
    padding: '12px 8px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'black',
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
    margin: '16px 0',
  },
  icon: {
    height: '24px',
    width: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '24px 24px',
    flexGrow: 0,
    marginRight: '8px',
  },
  content: {
    flexGrow: 1,
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 700,
  },
  chevron: {
    height: '24px',
    width: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '24px 24px',
    backgroundImage: `url(${images.CHEVRON_RIGHT_WHITE})`,
    flexGrow: 0,
  },
}));

type SideMenuLinkProps = Required<GtmTagProps> & {
  disabled?: boolean;
  chevron?: boolean;
  iconSrc: string;
  onClick?: () => void;
  href?: string;
};

export const SideMenuLink: FC<SideMenuLinkProps> = ({
  disabled,
  chevron,
  iconSrc,
  onClick,
  href,
  children,
  ...others
}) => {
  const classes = useStyles();
  const gtmTagProps = gtmTagPropsToDataset(others);

  const button = (
    <Button className={classes.button} disabled={disabled} onClick={onClick} {...gtmTagProps}>
      <div className={classes.icon} style={{ backgroundImage: `url(${iconSrc})` }}></div>
      <div className={classes.content}>{children}</div>
      {chevron && <div className={classes.chevron}></div>}
    </Button>
  );

  return href ? (
    <Link scroll href={href} {...gtmTagProps}>
      {button}
    </Link>
  ) : (
    <>{button}</>
  );
};
