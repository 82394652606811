import React from 'react';
import Image from 'next/image';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: '0 12px 0 0',
  },
  container: {
    minHeight: '60px',
    borderRadius: '13px',
    marginTop: (props: AuthStyleProps) => (props.marginTop ? '1rem' : '0'),
    backgroundColor: (props: AuthStyleProps) => `${!props.isVisible ? 'transparent' : theme.palette.Pavlova.main}`,
    padding: '12px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '1rem',
    },
  },
}));

type AuthStyleProps = {
  isVisible: boolean;
  marginTop: boolean;
};

export const FormMessage = ({ isSuccess = false, isError = false, message = '', marginTop = true }) => {
  const props: AuthStyleProps = { isVisible: isError || isSuccess, marginTop };
  const classes = useStyles(props);

  return (
    <Grid container item direction="row" alignItems="center" justifyContent="center" className={classes.container}>
      {(isError || isSuccess) && (
        <>
          <div className={classes.icon}>
            {isError ? (
              <Image src="/images/alert-triangle.svg" width={24} height={24} />
            ) : (
              <Image src="/images/check-circle-green.svg" width={24} height={24} />
            )}
          </div>
          {message}
        </>
      )}
    </Grid>
  );
};
