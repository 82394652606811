import React, { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { images } from 'features/home/urls';
import Image from 'next/image';
import { Link } from './link';
import { completeGtmTagProps } from 'utils/gtm-tag-utils';

const useStyles = makeStyles({
  margin: {
    marginRight: '8px',
  },
});

type Props = {
  mode: 'dark' | 'light';
  facebookUrl: string;
  instagramUrl: string;
  linkedinUrl: string;
  className?: string;
};

const gtmTag = completeGtmTagProps({ gtmEventCategory: 'Social' });

const Component: FC<Props> = ({ mode, facebookUrl, instagramUrl, linkedinUrl, className }) => {
  const classes = useStyles({ mode: mode ?? 'light' });

  return (
    <div className={className}>
      <Link
        className={classes.margin}
        href={facebookUrl}
        target="_blank"
        rel="noreferrer"
        {...gtmTag('Clicked Facebook')}
      >
        <Image width={24} height={24} src={mode === 'light' ? images.FACEBOOK : images.FACEBOOK_WHITE} alt="Facebook" />
      </Link>
      <Link
        className={classes.margin}
        href={instagramUrl}
        target="_blank"
        rel="noreferrer"
        {...gtmTag('Clicked Instagram')}
      >
        <Image
          width={24}
          height={24}
          src={mode === 'light' ? images.INSTAGRAM : images.INSTAGRAM_WHITE}
          alt="Instagram"
        />
      </Link>
      <Link
        className={classes.margin}
        href={linkedinUrl}
        target="_blank"
        rel="noreferrer"
        {...gtmTag('Clicked LinkedIn')}
      >
        <Image
          width={24}
          height={24}
          src={mode === 'light' ? images.LINKEDIN_BLACK : images.LINKEDIN_WHITE}
          alt="LinkedIn"
        />
      </Link>
    </div>
  );
};

export const SocialLinks = memo(Component);
