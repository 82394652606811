import { fetcher } from 'lib/fetcher';
import useSWR from 'swr';
import { GetCurrentExpertPresenceResponse } from '../models';
import { apis } from '../urls';

export const useGetCurrentExpertPresence = (shouldFetch: boolean) => {
  const {
    data: expertPresence,
    error,
    mutate,
  } = useSWR<GetCurrentExpertPresenceResponse>(() => (!shouldFetch ? null : apis.CURRENT_USER), fetcher);
  return {
    expertPresence,
    error,
    mutate,
  };
};
