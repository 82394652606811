import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { ResponsiveGrid } from 'components';
import { PartnerBranding as PartnerBrandingCms } from 'services/cms';

type Props = {
  partnerBranding: PartnerBrandingCms;
  responsive?: boolean;
};

const useStyles = makeStyles<Theme, { responsive?: boolean }>((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    width: '100%',
    padding: 20,
  },
  root: {
    background: theme.palette.common.white,
    width: (props) => (props.responsive ? '100%' : 'auto'),
  },
  logo: {
    maxWidth: (props) => (!props.responsive ? 250 : '100%'),
  },
}));

export function PartnerBranding(props: Props) {
  const classes = useStyles({ responsive: props.responsive });
  const grid = (
    <Grid className={classes.root} container alignItems="center" spacing={1} justifyContent="flex-start" wrap="nowrap">
      <Grid item>
        <img className={classes.logo} src={props.partnerBranding.logo.url} alt={props.partnerBranding.logo.alt} />
      </Grid>
    </Grid>
  );

  if (!props.responsive) {
    return grid;
  }

  return <ResponsiveGrid className={classes.container}>{grid}</ResponsiveGrid>;
}
