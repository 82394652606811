import { Button, Hidden, makeStyles, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { pages as authURLs } from 'features/auth/urls';
import { pages as referralUrls } from 'features/referrals/urls';
import { pages as accountURLs } from 'features/account/urls';
import { pages as contentPages } from 'features/content/urls';
import { pages as appointmentPages } from 'features/appointments/urls';
import { pages as mortgagePages } from 'features/mortgage/urls';
import { pages as canvasPages } from 'features/canvas/urls';

import { Link, Span, StructuredText, TypographyVariant } from 'components';
import { PageLink } from './true-page';
import { HeaderLink } from './header-link';
import { useReferredPeers } from 'features/referrals/hooks/use-referred-peers';
import { ReferralStatus } from 'features/referrals/models/referral-status';
import { completeGtmTagProps, gtmTagPropsToDataset } from 'utils/gtm-tag-utils';
import { StructuredText as DatoStructuredText } from 'datocms-structured-text-utils';

const gtmTag = completeGtmTagProps({
  gtmEventCategory: 'Header',
});

const useStyles = makeStyles((theme) => ({
  startedButton: {
    '&.MuiButton-containedSecondary:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    '&.MuiButton-containedPrimary:hover': {
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
  },
  buttonLink: {
    textDecoration: 'none',
  },
  buttonSpan: {
    fontSize: '1.0625rem',
    lineHeight: '24px',
    textDecoration: 'none',
  },
  transparentButton: {
    backgroundColor: 'transparent',
    color: theme.palette.Dark1.main,
    border: `2px solid ${theme.palette.Dark1.main}`,
    maxHeight: '48px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logInLink: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  spanClass: { marginRight: '12px' },
  pageLink: {
    marginTop: '0 !important',
    marginRight: '12px',
  },
  pageLinkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export type HeaderLinksProps = {
  isLoggedIn: boolean;
  pageLinks: PageLink[];
  textColor: string;
  linkClass: string;
  onClick: (link: PageLink) => void;
  variant: TypographyVariant;
  cmsPageLinks?: DatoStructuredText;
};

export const HeaderLinks: FC<HeaderLinksProps> = ({
  pageLinks,
  textColor,
  linkClass,
  onClick,
  isLoggedIn,
  variant,
  cmsPageLinks,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { data, isLoading } = useReferredPeers(isLoggedIn);

  if (cmsPageLinks) {
    return (
      <div className={classes.pageLinkWrapper}>
        <StructuredText
          data={cmsPageLinks}
          aClassName={linkClass}
          pClassName={classes.pageLink}
          pVariant="b2"
          GridItemWrapper={({ children }) => <span>{children}</span>}
        />
      </div>
    );
  }

  let redeemableAmount = 0;
  data?.referrals?.forEach((referral) => {
    if (referral?.status === ReferralStatus.AwaitingPayment) {
      redeemableAmount += referral?.referralBonus;
    }
  });

  const whiteish =
    textColor === '#FFF' ||
    textColor === '#FFFFFF' ||
    textColor === 'white' ||
    textColor === theme.palette.Pavlova.main;

  return (
    <>
      {pageLinks.includes('about-us') &&
        (!isLoggedIn ? (
          <HeaderLink
            text="About us"
            href={contentPages.ABOUT_US}
            color={textColor}
            className={linkClass}
            onClick={() => onClick('about-us')}
            variant={variant}
            {...gtmTag('Clicked About Us')}
          />
        ) : undefined)}
      {pageLinks.includes('articles') &&
        (!isLoggedIn ? (
          <HeaderLink
            text="Articles"
            href={contentPages.ARTICLES_PAGE}
            color={textColor}
            className={linkClass}
            onClick={() => onClick('articles')}
            variant={variant}
            {...gtmTag('Clicked Articles')}
          />
        ) : undefined)}
      {pageLinks.includes('talk-to-us') &&
        (!isLoggedIn ? (
          <Button
            className={classes.transparentButton}
            style={{ color: textColor, borderColor: textColor }}
            onClick={() => onClick('talk-to-us')}
            {...gtmTagPropsToDataset(gtmTag('Clicked Talk to us'))}
          >
            <span className={classes.buttonSpan}>Talk to us</span>
          </Button>
        ) : undefined)}
      {pageLinks.includes('get-started') &&
        (!isLoggedIn ? (
          <>
            <Hidden mdDown>
              <Link
                key="getStarted"
                href={contentPages.GET_STARTED}
                onClick={() => onClick('get-started')}
                className={classes.buttonLink}
                {...gtmTagPropsToDataset(gtmTag('Clicked Get Started'))}
              >
                <Button
                  className={classes.startedButton}
                  color={whiteish ? 'secondary' : 'primary'}
                  {...gtmTagPropsToDataset(gtmTag('Clicked Get Started'))}
                >
                  <span className={classes.buttonSpan}>Get started</span>
                </Button>
              </Link>
            </Hidden>
            <Hidden lgUp>
              <HeaderLink
                text="Get Started"
                key="get-started"
                href={contentPages.GET_STARTED}
                color={textColor}
                className={linkClass}
                onClick={() => onClick('get-started')}
                variant={variant}
                {...gtmTag('Clicked Get Started')}
              />
            </Hidden>
          </>
        ) : undefined)}
      {pageLinks.includes('refer-and-earn') &&
        (isLoggedIn ? (
          <HeaderLink
            text="Refer &amp; earn"
            href={referralUrls.REFER_PAGE}
            color={textColor}
            className={linkClass}
            onClick={() => onClick('refer-and-earn')}
            badgeText={isLoading ? '' : `$${redeemableAmount.toFixed(2)}`}
            isBadgeHighlighted={redeemableAmount > 0}
            variant={variant}
            {...gtmTag('Clicked Refer & Earn')}
          />
        ) : undefined)}
      {pageLinks.includes('account') &&
        (isLoggedIn ? (
          <HeaderLink
            text="Account"
            key="account"
            href={accountURLs.ACCOUNT}
            color={textColor}
            className={linkClass}
            onClick={() => onClick('account')}
            variant={variant}
            {...gtmTag('Clicked Account')}
          />
        ) : undefined)}
      {pageLinks.includes('log-out') &&
        (isLoggedIn ? (
          <HeaderLink
            text="Log out"
            href={authURLs.LOG_OUT_PAGE}
            color={textColor}
            className={linkClass}
            onClick={() => onClick('log-out')}
            variant={variant}
            {...gtmTag('Clicked Log Out')}
          />
        ) : undefined)}
      {pageLinks.includes('log-in-sign-up') &&
        (!isLoggedIn ? (
          <p className={classes.logInLink} key="logInSignUp">
            <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
              Don&apos;t have an account?&nbsp;
            </Span>
            <HeaderLink
              text="Sign up"
              href={authURLs.SIGN_UP_PAGE}
              color={theme.palette.Blue.main}
              className={linkClass}
              onClick={() => onClick('log-in-sign-up')}
              variant={variant}
              {...gtmTag('Clicked Sign Up')}
            />
          </p>
        ) : undefined)}
      {pageLinks.includes('not-ready-to-schedule') &&
        (!isLoggedIn ? (
          <p className={classes.logInLink} key="not-ready-to-schedule">
            <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
              Not ready to talk to us yet?
            </Span>
            <HeaderLink
              text="Create an account"
              href={authURLs.SIGN_UP_PAGE}
              color={theme.palette.Blue.main}
              className={linkClass}
              onClick={() => onClick('not-ready-to-schedule')}
              variant={variant}
              {...gtmTag('Clicked Sign Up')}
            />
          </p>
        ) : undefined)}
      {pageLinks.includes('schedule-a-chat') &&
        (!isLoggedIn ? (
          <p className={classes.logInLink} key="schedule-a-chat">
            <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
              Not ready to talk to us yet?
            </Span>
            <HeaderLink
              text="Schedule a chat"
              href={appointmentPages.NEW_APPOINTMENT}
              color={theme.palette.Blue.main}
              className={linkClass}
              onClick={() => onClick('schedule-a-chat')}
              variant={variant}
              {...gtmTag('Clicked Schedule A Chat')}
            />
          </p>
        ) : undefined)}
      {pageLinks.includes('want-to-save-progress') &&
        (!isLoggedIn ? (
          <p className={classes.logInLink} key="want-to-save-progress">
            <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
              Want to save your progress?
            </Span>
            <HeaderLink
              text="Create an account"
              href={authURLs.SIGN_UP_PAGE}
              color={theme.palette.Blue.main}
              className={linkClass}
              onClick={() => onClick('want-to-save-progress')}
              variant={variant}
              {...gtmTag('Clicked Sign Up')}
            />
          </p>
        ) : undefined)}
      {pageLinks.includes('refinance') && (
        <p className={classes.logInLink} key="refinance">
          <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
            Looking to refinance?
          </Span>
          <HeaderLink
            text="See how much you could save"
            href={mortgagePages.REPAYMENT_SAVINGS_CALCULATOR}
            color={theme.palette.Blue.main}
            className={linkClass}
            onClick={() => onClick('refinance')}
            variant={variant}
            {...gtmTag('Clicked Refinance Calculator')}
          />
        </p>
      )}
      {pageLinks.includes('new-loan') && (
        <p className={classes.logInLink} key="new-loan">
          <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
            Looking for a new loan?
          </Span>
          <HeaderLink
            text="See how much you could borrow"
            href={mortgagePages.BORROWING_POWER_CALCULATOR}
            color={theme.palette.Blue.main}
            className={linkClass}
            onClick={() => onClick('new-loan')}
            variant={variant}
            {...gtmTag('Clicked Borrowing Power Calculator')}
          />
        </p>
      )}
      {pageLinks.includes('no-code-schedule-a-chat') &&
        (!isLoggedIn ? (
          <p className={classes.logInLink} key="noCodeScheduleAChat">
            <Span style={{ color: textColor }} variant="button1" className={classes.spanClass}>
              Don&apos;t have a code?
            </Span>
            <HeaderLink
              text="Schedule a chat"
              href={appointmentPages.NEW_APPOINTMENT}
              color={theme.palette.Blue.main}
              className={linkClass}
              onClick={() => onClick('no-code-schedule-a-chat')}
              variant={variant}
              {...gtmTag('Clicked Schedule A Chat')}
            />
          </p>
        ) : undefined)}
      {pageLinks.includes('join-session') && (
        <p className={classes.logInLink} key="join-session">
          <Span style={{ color: textColor }} variant="button1" marginTop="none" className={classes.spanClass}>
            Already chatting with an expert?
          </Span>
          <HeaderLink
            text="Join session"
            href={canvasPages.ENTER_CODE}
            color={theme.palette.Blue.main}
            className={linkClass}
            onClick={() => onClick('join-session')}
            variant={variant}
            {...gtmTag('Clicked Join session')}
          />
        </p>
      )}
    </>
  );
};
