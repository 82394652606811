import useSWR from 'swr';
import { StructuredTextDocument } from 'react-datocms';
import { graphAPI } from 'services/cms/fetcher';

export type GlobalCmsData = {
  globalConfiguration: {
    footer: { value: StructuredTextDocument };
    instagramUrl: string;
    linkedinUrl: string;
    facebookUrl: string;
    email: string;
    mediaEmail: string;
    phone: string;
    businessHours: string;
    publicContentLinks: { value: StructuredTextDocument };
    publicLegalLinks: { value: StructuredTextDocument };
    authenticatedContentLinks: { value: StructuredTextDocument };
    authenticatedLegalLinks: { value: StructuredTextDocument };
  };
};

const query = `query globalData {
  globalConfiguration {
    footer {
      value
    }
    instagramUrl
    linkedinUrl
    email
    mediaEmail
    facebookUrl
    publicContentLinks {
      value
    }
    publicLegalLinks {
      value
    }
    authenticatedContentLinks {
      value
    }
    authenticatedLegalLinks {
      value
    }
    phone
    businessHours
  }
}
`;

export function useGlobalCmsData() {
  const { data, error } = useSWR<GlobalCmsData>(query, graphAPI, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    data: data?.globalConfiguration,
    error,
    isLoading: !error && !data,
  };
}
