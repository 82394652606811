import useSWR from 'swr';
import { fetcher } from 'lib/fetcher';
import { apis } from '../urls';
import { PeerReferralsResponse } from '../models';

export function useReferredPeers(shouldCall: boolean) {
  const apiUrl = shouldCall ? apis.PEER_REFERRALS : null;

  const { data, error } = useSWR<PeerReferralsResponse>(apiUrl, fetcher);

  return {
    data: data,
    error,
    isLoading: !error && !data,
  };
}
