import { useState, useEffect } from 'react';
import cx from 'classnames';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import { UserPresenceStatus } from 'packages/db-website/src';
import { useGetCurrentExpertPresence, useSetCurrentExpertPresence } from '../hooks';
import { Switch } from './switch';
import { Span_Legacy } from 'components';
import { useAuth } from 'context';
import { SAVINGS_EXPERTS_GROUP } from 'features/canvas/constants';

const useStyles = makeStyles(() => ({
  container: {},
  label: {
    paddingTop: 2,
    marginRight: 10,
  },
}));

type Props = {
  className?: string;
};

export function ToggleExpertPresence(props: Props) {
  const classes = useStyles();
  const { user } = useAuth();
  const { expertPresence } = useGetCurrentExpertPresence(user !== null && user?.groups.includes(SAVINGS_EXPERTS_GROUP));
  const { setPresence } = useSetCurrentExpertPresence();
  const [online, setOnline] = useState(false);

  useEffect(() => {
    if (user) {
      const currentOnline = expertPresence?.status === UserPresenceStatus.ONLINE;
      setOnline(currentOnline);
    }
  }, [expertPresence, user]);

  const handleChange = () => {
    setPresence({
      status: online ? UserPresenceStatus.OFFLINE : UserPresenceStatus.ONLINE,
    });
  };

  if (!user?.groups.includes(SAVINGS_EXPERTS_GROUP)) {
    return null;
  }

  return (
    <div className={cx(classes.container, props.className)}>
      <Grid container item xs={12} alignItems="center" justifyContent="flex-end">
        <Hidden smDown>
          <Span_Legacy variant="body2" className={classes.label}>
            Available
          </Span_Legacy>
        </Hidden>
        <Switch checked={online} onChange={handleChange} />
      </Grid>
    </div>
  );
}
