import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { Button, makeStyles, Popover, Slide, Theme } from '@material-ui/core';
import { images } from 'features/home/urls';
import { SideMenuLink } from './side-menu-link';
import { pages } from 'features/appointments/urls';
import { ROBOTO_FONT_FAMILY } from './Layout/theme';
import { useGlobalCmsData } from 'hooks/use-global-cms-data';
import { completeGtmTagProps } from 'utils/gtm-tag-utils';

// dynamic import to keep twilio-video out of main bundle
const CanvasSideMenuLink = dynamic(() => import('./canvas-side-menu-link'));

const useStyles = makeStyles<Theme>((theme) => ({
  closeButton: {
    backgroundImage: `url(${images.CLOSE_WHITE})`,
    backgroundColor: 'transparent',
    border: 'none',
    width: '32px',
    minWidth: '32px',
    height: '32px',
    backgroundSize: '24px 24px',
    backgroundPosition: 'center center',
    cursor: 'pointer',
    padding: '0',
    backgroundRepeat: 'no-repeat',
  },
  popOver: {
    '& .MuiPaper-root': {
      backgroundColor: 'rgba(120,120,120,0.3)',
      top: '0!important',
      left: '0!important',
      borderRadius: '0!important',
      width: '100%!important',
      minWidth: '100%!important',
      maxWidth: '100%!important',
      height: '100%!important',
      minHeight: '100%!important',
      maxHeight: '100%!important',
    },
  },
  header: {
    fontSize: '17px',
    fontWeight: 700,
    marginTop: '16px',
    fontFamily: ROBOTO_FONT_FAMILY,
  },
  sub: {
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: ROBOTO_FONT_FAMILY,
  },
  background: {
    backgroundColor: theme.palette.Vegemite.main,
    color: theme.palette.Pavlova.main,
    height: '100vh',
    width: '366px',
    right: '0px',
    left: 'auto',
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    padding: '16px',
  },
}));

export type TalkToUsSideMenuProps = {
  enableTrueCanvas: boolean;
  onClose: () => void;
};

const gtmTag = completeGtmTagProps({
  gtmEventCategory: 'Side Menu',
});

export const TalkToUsSideMenu: FC<TalkToUsSideMenuProps> = ({ onClose, enableTrueCanvas }) => {
  const classes = useStyles();
  const { data } = useGlobalCmsData();

  return (
    <Popover
      className={classes.popOver}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={true}
      elevation={0}
      transitionDuration={0}
      marginThreshold={0}
      onClick={onClose}
    >
      <Slide direction="left" in={true}>
        <div className={classes.background}>
          <Button className={classes.closeButton} />
          <div className={classes.header}>Talk to us</div>
          <div className={classes.sub}>
            Get in touch with one of our friendly experts who can help with all of your home loan related questions.
          </div>
          {enableTrueCanvas && <CanvasSideMenuLink />}
          <SideMenuLink
            iconSrc={images.CALENDAR_YELLOW}
            chevron
            href={pages.NEW_APPOINTMENT}
            {...gtmTag('Clicked Schedule A Chat')}
          >
            Schedule a chat
          </SideMenuLink>
          <SideMenuLink iconSrc={images.PHONE_YELLOW} href={`tel:${data.phone}`} {...gtmTag(`Clicked ${data.phone}`)}>
            {data.phone}
          </SideMenuLink>
          <SideMenuLink
            iconSrc={images.EMAIL_YELLOW}
            href={`mailto:${data.email}`}
            {...gtmTag(`Clicked ${data.email}`)}
          >
            {data.email}
          </SideMenuLink>
        </div>
      </Slide>
    </Popover>
  );
};
