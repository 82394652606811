import { useCallback, useEffect, useState } from 'react';
import { equals } from 'ramda';

export function useSessionStorage<T = unknown>(key: string, initialValue?: T): [T, (v: T | ((old: T) => T)) => void] {
  const getValue = useCallback((): T => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(getValue);

  const setValue = useCallback(
    (v: T | ((old: T) => T)) => {
      let res: T;
      if (typeof v === 'function') {
        res = (v as (old: T) => T)(getValue());
      } else {
        res = v as T;
      }
      setStoredValue(res);
      sessionStorage.setItem(key, JSON.stringify(res));
      window.dispatchEvent(new Event('sessionStorageChange'));
    },
    [getValue, key]
  );

  const onStorageEvent = useCallback(() => {
    const nvs = sessionStorage.getItem(key);
    if (nvs) {
      setStoredValue((ov) => {
        const nv = JSON.parse(nvs);
        return equals(nv, ov) ? ov : nv;
      });
    }
  }, [key]);

  useEffect(() => {
    window.addEventListener('sessionStorageChange', onStorageEvent);
    return () => {
      window.removeEventListener('sessionStorageChange', onStorageEvent);
    };
  }, [onStorageEvent]);

  return [storedValue, setValue];
}
