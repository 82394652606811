import { makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useAuth } from 'context';
import { HeaderLinksResponsive } from './header-links-responsive';
import { ReferralBanner } from 'features/referrals/components';
import { Theme, useTheme } from '@material-ui/core/styles';
import { PageLink } from './true-page';
import { TalkToUsSideMenu } from './talk-to-us-side-menu';
import { EnvironmentBanner } from './environment-banner';
import { useFeatureFlags } from 'context';
import { PartnerBranding } from 'services/cms';
import { StructuredText } from 'datocms-structured-text-utils';

export type HeaderColor = 'pavlova' | 'light-grey' | 'blue' | 'sponge-cake' | 'vegemite';
export type HeaderProps = {
  textColor?: string;
  backgroundColor?: string;
  headerColor?: HeaderColor;
  pageLinks: PageLink[];
  isSticky?: boolean;
  isDarkMode?: boolean;
  showReferralBanner?: boolean;
  partnerBranding?: PartnerBranding;
  cmsPageLinks?: StructuredText;
};

const useStyles = makeStyles((theme) => ({
  outer: { backgroundColor: 'transparent', userSelect: 'none', zIndex: 10, position: 'relative' },
  outerSticky: {
    position: 'absolute',
    inset: '0px',
    display: 'block',
    zIndex: 2,
    clip: 'rect(auto, auto, auto, auto)',
    '-webkit-mask-image': '-webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%)',
    pointerEvents: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  banner: {
    alignItems: 'center',
    height: '80px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: { height: '60px' },
  },
  innerSticky: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 'fit-content',
    zIndex: 99,
    transform: 'translateZ(0px)',
    backfaceVisibility: 'hidden',
    willChange: 'transform',
    perspective: '1000px',
    justifyContent: 'center',
    pointerEvents: 'all',
  },
}));

export const getHeaderColorScheme = (theme: Theme, headerColor?: HeaderColor) => {
  if (headerColor === 'sponge-cake') {
    return {
      backgroundColor: theme.palette.SpongeCake.main,
      textColor: theme.palette.Vegemite.main,
      isDarkMode: false,
    };
  } else if (headerColor === 'blue') {
    return {
      backgroundColor: theme.palette.Blue.main,
      textColor: theme.palette.Pavlova.main,
      isDarkMode: true,
    };
  } else if (headerColor === 'light-grey') {
    return {
      backgroundColor: theme.palette.Background.main,
      textColor: theme.palette.Vegemite.main,
      isDarkMode: false,
    };
  } else if (headerColor === 'pavlova') {
    return {
      backgroundColor: theme.palette.Pavlova.main,
      textColor: theme.palette.Vegemite.main,
      isDarkMode: false,
    };
  } else if (headerColor === 'vegemite') {
    return {
      backgroundColor: theme.palette.Vegemite.main,
      textColor: theme.palette.Pavlova.main,
      isDarkMode: true,
    };
  } else return {};
};

export const Header: FC<HeaderProps> = ({
  textColor,
  backgroundColor,
  headerColor,
  pageLinks,
  isSticky,
  isDarkMode,
  showReferralBanner,
  partnerBranding,
  cmsPageLinks,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [popupOpen, setPopupOpen] = useState(false);
  const { canvas: canvasEnabled } = useFeatureFlags();

  const scheme = getHeaderColorScheme(theme, headerColor);
  backgroundColor = backgroundColor ?? scheme.backgroundColor;
  textColor = textColor ?? scheme.textColor;
  isDarkMode = isDarkMode ?? scheme.isDarkMode;

  const OuterSticky: FC = ({ children }) => {
    return (
      <div className={classes.outerSticky}>
        <div className={classes.innerSticky} style={{ backgroundColor }}>
          {children}
        </div>
      </div>
    );
  };

  const OuterFixed: FC = ({ children }) => {
    return (
      <div className={classes.outer} style={{ backgroundColor }}>
        {children}
      </div>
    );
  };

  const Outer = isSticky ? OuterSticky : OuterFixed;

  return (
    <Outer>
      <EnvironmentBanner />
      {popupOpen && (
        <TalkToUsSideMenu enableTrueCanvas={canvasEnabled} onClose={() => setPopupOpen(false)}></TalkToUsSideMenu>
      )}
      <div className={classes.banner}>
        <HeaderLinksResponsive
          isDarkMode={isDarkMode}
          textColor={textColor}
          pageLinks={pageLinks}
          headerColor={headerColor}
          onClick={(link) => {
            if (link === 'talk-to-us') {
              setPopupOpen(true);
            }
          }}
          partnerBranding={partnerBranding}
          cmsPageLinks={cmsPageLinks}
        />
      </div>
      {!user && showReferralBanner && <ReferralBanner />}
    </Outer>
  );
};
