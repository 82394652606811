import { Grid, Hidden, makeStyles } from '@material-ui/core';
import React, { FC, useRef, useState } from 'react';
import { Theme, useTheme } from '@material-ui/core/styles';
import { HeaderLinks } from './header-links';
import Popover from '@material-ui/core/Popover';
import { ToggleButton } from '@material-ui/lab';
import { PageLink } from './true-page';
import { useEffect } from 'react';
import { images } from 'features/home/urls';
import cx from 'classnames';
import { HeaderColor, Link, PartnerBranding } from 'components';
import { ToggleExpertPresence } from 'features/expert-availability/components';
import { useAuth } from 'context';
import { PartnerBranding as PartnerBrandingCms } from 'services/cms';
import { StructuredText } from 'datocms-structured-text-utils';

const useStyles = makeStyles<Theme, HeaderLinksResponsiveProps>((theme) => ({
  navRight: {
    width: 'fit-content',
    height: '100%',
    right: 0,
    alignItems: 'center',
    '&>a': {
      marginLeft: '24px',
    },
    '&>a>button': {
      marginLeft: '-12px',
    },
  },
  navCenter: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&>a,&>button': {
      marginLeft: '24px',
      marginRight: '24px',
    },
  },
  navLeft: {
    flex: 1,
    height: '100%',
    marginLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&>a,&>button': {
      marginLeft: '24px',
      marginRight: '24px',
    },
  },
  mobileHeader: {
    width: 'unset',
    margin: '0 4px',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    position: 'relative',
  },
  mobileLogo: {
    textAlign: 'center',
  },
  mobileHeaderPopup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    marginBottom: '48px',
  },
  navContainer: {
    flexShrink: 0,
    flexGrow: 1,
    width: '984px',
    '@media (max-width:1166px)': {
      maxWidth: 1024,
    },
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
  },
  logoImg: {
    width: '108px',
    height: '32px',
    [theme.breakpoints.down('md')]: { height: '24px', width: '81px', marginRight: 0 },
    userSelect: 'none',
    verticalAlign: 'middle',
    cursor: 'pointer',
    marginRight: '24px',
  },
  popOver: {
    '& .MuiPaper-root': {
      left: '0!important',
      width: '100%!important',
      minWidth: '100%!important',
      maxWidth: '100%!important',
      top: '0!important',
      height: '100%!important',
      minHeight: '100%!important',
      maxHeight: '100%!important',
      borderRadius: '0!important',
      backgroundColor: 'black',
    },
  },
  hamburger: (props) => ({
    backgroundImage: `url(${props.isDarkMode ? images.HAMBURGER_WHITE : images.HAMBURGER})`,
    backgroundSize: '24px',
    border: 'none',
    width: '32px',
    height: '32px',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
  phone: (props) => ({
    backgroundImage: `url(${props.isDarkMode ? images.PHONE_WHITE : images.PHONE_BLUE})`,
    backgroundSize: '24px',
    border: 'none',
    width: '32px',
    height: '32px',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
  placeholder: {
    width: '32px',
    height: '32px',
  },
  whiteClose: {
    backgroundImage: `url(${images.CLOSE_WHITE})`,
    backgroundSize: '24px',
    border: 'none',
    width: '32px',
    height: '32px',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  mobileLinkClass: {
    '&>span': {
      lineHeight: 'unset',
    },
    marginBottom: '48px',
    display: 'block',
    textAlign: 'center',
  },
  mobileLinkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& button.MuiButton-containedSecondary:hover': {
      backgroundColor: '#1967E0',
    },
    '& button': {
      backgroundColor: theme.palette.Blue.main,
      color: theme.palette.Pavlova.main,
      margin: '15px 0',
      width: '100%',
      maxWidth: '375px',
    },
    '&>a': {
      width: '100%',
      padding: '0 32px',
      textAlign: 'center',
    },
  },
  navPadder: {
    width: '52px',
    flexShrink: 1,
  },
  navOuterWrapper: {
    display: 'flex',
    flexGrow: 1,
    height: '80px',
  },
  desktopLinkClass: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  mobileExpertToggle: {
    paddingRight: 6,
  },
  headerLinksBody: {
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '12px',
    },
  },
}));

const leftLinks: PageLink[] = ['refer-and-earn', 'account', 'articles', 'log-out', 'about-us', 'log-in'];
const rightLinks: PageLink[] = [
  'talk-to-us',
  'get-started',
  'log-in-sign-up',
  'sign-up-sign-in',
  'no-code-schedule-a-chat',
  'not-ready-to-schedule',
  'want-to-save-progress',
  'back-to-login',
  'new-loan',
  'refinance',
  'schedule-a-chat',
  'join-session',
];
const textLinks: PageLink[] = [
  'log-in-sign-up',
  'sign-up-sign-in',
  'no-code-schedule-a-chat',
  'not-ready-to-schedule',
  'schedule-a-chat',
  'join-session',
];

export type HeaderLinksResponsiveProps = {
  pageLinks: PageLink[];
  textColor: string;
  isDarkMode: boolean;
  headerColor: HeaderColor;
  onClick: (pageLink: PageLink) => void;
  bodyLinks?: boolean;
  partnerBranding?: PartnerBrandingCms;
  cmsPageLinks?: StructuredText;
};

export const HeaderLinksResponsive: FC<HeaderLinksResponsiveProps> = (props) => {
  const { textColor, pageLinks, isDarkMode, headerColor, onClick } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const { user, isUserInitialised } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const gtmTagForLogo: Required<GtmTagProps> = {
    gtmEventCategory: 'Header',
    gtmEventAction: 'Clicked Logo',
  };

  useEffect(() => {
    setIsLoggedIn(Boolean(isUserInitialised && user));
  }, [user, isUserInitialised]);

  useEffect(() => {
    if (popupOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = null;
    }
  }, [popupOpen]);

  const logoSource = isDarkMode
    ? headerColor === 'blue'
      ? images.LOGO_WHITE_YELLOW_DOT
      : images.LOGO_WHITE
    : images.LOGO;

  if (props.bodyLinks) {
    return (
      <>
        {(pageLinks.filter((pl) => textLinks.includes(pl)).length > 0 || props.cmsPageLinks) && (
          <Hidden lgUp>
            <div className={classes.headerLinksBody}>
              <HeaderLinks
                isLoggedIn={isLoggedIn}
                linkClass={classes.desktopLinkClass}
                pageLinks={pageLinks.filter((pl) => textLinks.includes(pl))}
                textColor={textColor}
                onClick={(link) => onClick(link)}
                variant="button1"
                cmsPageLinks={props.cmsPageLinks}
              />
            </div>
          </Hidden>
        )}
      </>
    );
  }

  return (
    <>
      <Hidden lgUp>
        <Grid container className={classes.mobileHeader} justifyContent="space-between" alignItems="center">
          {props.partnerBranding ? (
            <Grid item>
              <PartnerBranding partnerBranding={props.partnerBranding} responsive />
            </Grid>
          ) : (
            <>
              <Grid item xs={3}>
                {pageLinks.filter((pl) => pl !== 'talk-to-us' && !textLinks.includes(pl)).length > 1 ? (
                  <ToggleButton
                    value="="
                    className={classes.hamburger}
                    onClick={() => setPopupOpen(true)}
                  ></ToggleButton>
                ) : (
                  <div className={classes.placeholder}></div>
                )}
              </Grid>
              <Grid className={classes.mobileLogo} item xs={6}>
                <Link href="/" {...gtmTagForLogo}>
                  <img className={classes.logoImg} src={logoSource} width="100%" alt="True" />
                </Link>
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end">
                {pageLinks.includes('talk-to-us') ? (
                  <ToggleButton
                    value="/"
                    className={classes.phone}
                    onClick={() => onClick('talk-to-us')}
                  ></ToggleButton>
                ) : null}
                <ToggleExpertPresence className={classes.mobileExpertToggle} />
              </Grid>
            </>
          )}
        </Grid>
        <Popover
          className={classes.popOver}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={popupOpen}
          elevation={0}
          transitionDuration={0}
          marginThreshold={0}
          anchorEl={anchorEl.current}
        >
          <div className={cx(classes.mobileHeader, classes.mobileHeaderPopup)}>
            <ToggleButton value="X" className={classes.whiteClose} onClick={() => setPopupOpen(false)}></ToggleButton>
            <Link href="/" {...gtmTagForLogo}>
              <img className={classes.logoImg} src={images.LOGO_WHITE} width="100%" alt="True" />
            </Link>
            <div className={classes.placeholder}></div>
          </div>
          <div className={classes.mobileLinkWrapper}>
            <HeaderLinks
              isLoggedIn={isLoggedIn}
              linkClass={classes.mobileLinkClass}
              pageLinks={pageLinks.filter((pl) => pl != 'talk-to-us')}
              textColor={theme.palette.Pavlova.main}
              onClick={(link) => onClick(link)}
              variant="d4"
            />
          </div>
        </Popover>
      </Hidden>
      <Hidden mdDown>
        <div className={classes.navOuterWrapper}>
          <div className={classes.navPadder}></div>
          <nav className={classes.navContainer}>
            {!props.partnerBranding ? (
              <Link href="/">
                <img className={classes.logoImg} src={logoSource} width="100%" alt="True" />
              </Link>
            ) : (
              <PartnerBranding partnerBranding={props.partnerBranding} />
            )}
            <Grid container className={isLoggedIn ? classes.navLeft : classes.navCenter}>
              <HeaderLinks
                isLoggedIn={isLoggedIn}
                linkClass={classes.desktopLinkClass}
                pageLinks={pageLinks.filter((l) => leftLinks.includes(l))}
                textColor={textColor}
                onClick={(link) => onClick(link)}
                variant="button1"
              />
            </Grid>
            <Grid container className={classes.navRight}>
              <HeaderLinks
                isLoggedIn={isLoggedIn}
                linkClass={classes.desktopLinkClass}
                pageLinks={pageLinks.filter((l) => rightLinks.includes(l))}
                textColor={textColor}
                onClick={(link) => onClick(link)}
                variant="button1"
                cmsPageLinks={props.cmsPageLinks}
              />
              <ToggleExpertPresence />
            </Grid>
          </nav>
          <div className={classes.navPadder}></div>
        </div>
      </Hidden>
    </>
  );
};
