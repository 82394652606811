import React, { FC, useEffect } from 'react';
import { Container, Grid, Hidden, Link, makeStyles } from '@material-ui/core';
import { useAuth } from 'context';
import { useHealthz } from 'features/home/hooks/use-healthz';
import { Healthz } from 'features/home/models/health';
import { useKeyPress } from 'hooks/use-key-press';
import { useTimedStateTransition } from 'hooks/use-timed-state-transition';
import { UserData } from 'features/auth/models';
import { fetcher } from 'lib/fetcher';
import { apis } from 'features/auth/urls';
import cx from 'classnames';
import { getEnvName } from 'utils/get-env-name';
import { useSessionStorage } from 'hooks/use-session-storage';
import { ResponsiveGrid } from './Layout';

export const OVERRIDE_VISIBILITY = 'BannerVisibilityOverride';
export const GRID_VISIBILITY = 'GridVisibilityOverride';

const useStyles = makeStyles(() => ({
  outer: {
    color: 'white',
    lineHeight: '30px',
    position: 'relative',
  },
  development: {
    backgroundColor: 'DarkBlue',
  },
  production: {
    backgroundColor: 'DarkGreen',
  },
  test: {
    backgroundColor: 'DarkRed',
  },
  link: { color: 'white', marginLeft: '12px', fontWeight: 400, lineHeight: '30px' },
  center: { textAlign: 'center', position: 'absolute', width: '100%', left: 0, pointerEvents: 'none' },
  centerSpan: { textAlign: 'center', width: '100%', display: 'block', lineHeight: '30px' },
  right: { textAlign: 'right' },
  gridOverlay: {
    position: 'fixed',
    height: '100vh',
    pointerEvents: 'none',
    width: '100vw',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    '& > *': { paddingTop: 0, paddingBottom: 0 },
  },
  gridOverlayItem: {
    backgroundColor: 'rgba(255, 0, 0, 0.14)',
    height: '100vh',
  },
}));

const copyData = async (user: User, env: string, healthz: Healthz) => {
  let userData: UserData = null;
  try {
    userData = await fetcher(apis.USER);
  } catch {
    // do nothing
  }

  let debugString = `DateTime: ${new Date().toLocaleString()}
Environment: ${env}
Version: ${healthz?.version}
URL: ${window?.location}
UserId: ${user?.id}`;

  if (user?.id) {
    debugString = `${debugString}
Email: ${user?.email}
MercuryId: ${user?.mercuryContactId ?? userData?.mercuryContactId}
SalesforceId: ${userData?.salesforceAccountId}
ReferrerCode: ${userData?.referrerCode}
PrivacyVersion: ${userData?.privacyVersion}
PrivacyAcceptedAt: ${userData?.privacyAcceptedAt}
CalculatorSelection: ${userData?.calculator?.selection}`;
  }

  navigator.clipboard.writeText(debugString);
};

export const EnvironmentBanner: FC = () => {
  const env = getEnvName();
  const classes = useStyles();
  const { user } = useAuth();
  const { healthzData } = useHealthz();
  const [hidden, setHidden] = useSessionStorage(OVERRIDE_VISIBILITY, env === 'production');
  const [grid, setGrid] = useSessionStorage(GRID_VISIBILITY, false);
  const bannerToggle = useKeyPress('B', true, false, true);
  const { state: copyState, start: copyStart } = useTimedStateTransition<'hidden' | 'displayed'>([
    ['hidden', 0],
    ['displayed', 2000],
    ['hidden'],
  ]);
  const { state: hideState, start: hideStart } = useTimedStateTransition<'visible' | 'hidden' | 'message'>([
    ['visible', 0],
    ['message', 2000],
    ['hidden'],
  ]);

  useEffect(() => {
    if (bannerToggle) {
      setHidden((hidden) => !hidden);
    }
  }, [bannerToggle, setHidden]);

  useEffect(() => {
    if (hideState === 'hidden') {
      setHidden(true);
    }
  }, [hideState, setHidden]);

  return hidden ? (
    <></>
  ) : (
    <>
      <div
        className={cx(
          classes.outer,
          env === 'development' ? classes.development : env === 'test' ? classes.test : classes.production
        )}
      >
        {copyState === 'displayed' ? (
          <span className={classes.centerSpan}>*** Diagnostic data copied to clipboard ***</span>
        ) : hideState === 'message' ? (
          <span className={classes.centerSpan}>*** Press Ctrl-Shift-B to redisplay the banner ***</span>
        ) : (
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={6}>
                {`${env}: ${healthzData?.version}`}
              </Grid>
              {grid ? (
                <div className={classes.center}>
                  <Hidden only={['sm', 'md', 'lg', 'xl']}>XS</Hidden>
                  <Hidden only={['xs', 'md', 'lg', 'xl']}>SM</Hidden>
                  <Hidden only={['xs', 'sm', 'lg', 'xl']}>MD</Hidden>
                  <Hidden only={['xs', 'sm', 'md', 'xl']}>LG</Hidden>
                  <Hidden only={['xs', 'sm', 'md', 'lg']}>XL</Hidden>
                </div>
              ) : (
                <Hidden xsDown>
                  <div className={classes.center}>{user?.email}</div>
                </Hidden>
              )}
              <Grid item xs={6} className={classes.right}>
                <Link
                  className={classes.link}
                  onClick={() => {
                    copyStart();
                    copyData(user, env, healthzData);
                  }}
                >
                  <Hidden xsDown>Copy diagnostics</Hidden>
                  <Hidden smUp>Copy</Hidden>
                </Link>
                <Link className={classes.link} onClick={() => setGrid((g) => !g)}>
                  {grid ? 'Grid off' : 'Grid'}
                </Link>
                <Link className={classes.link} onClick={hideStart}>
                  Hide
                </Link>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
      {grid && (
        <>
          <Hidden smDown>
            <ResponsiveGrid className={classes.gridOverlay}>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={1}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
            </ResponsiveGrid>
          </Hidden>
          <Hidden mdUp>
            <ResponsiveGrid className={classes.gridOverlay}>
              <Grid item xs={3}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.gridOverlayItem}></div>
              </Grid>
            </ResponsiveGrid>
          </Hidden>
        </>
      )}
    </>
  );
};
