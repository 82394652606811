export const pages = {
  ABOUT_US: '/about-us',
  ARTICLES_PAGE: '/articles',
  ARTICLE_PAGE: '/articles/[slug]',
  PRIVACY_PAGE: '/privacy',
  TERMS_PAGE: '/terms',
  GET_STARTED: '/get-started',
  NOT_FOUND: '/404',
  SERVER_ERROR: '/500',
};
