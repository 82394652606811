import useSWR from 'swr';
import { UserPresenceSummary } from '../models';
import { apis } from '../urls';
import { fetcher } from 'lib/fetcher';

export const useGetExpertAvailabilitySummary = (fetchSummary: boolean) => {
  const { error, data } = useSWR<UserPresenceSummary>(
    () => (!fetchSummary ? null : apis.AVAILABILITY_SUMMARY),
    fetcher,
    {
      refreshInterval: 1000 * 60 * 2, // two minutes
    }
  );
  return { error, userPresenceSummary: data };
};
