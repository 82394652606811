import { useEffect, useState } from 'react';

export const useKeyPress = (targetKey: string, ctrlKey: boolean, altKey: boolean, shiftKey: boolean) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  useEffect(() => {
    function downHandler(e: KeyboardEvent) {
      if (e.key === targetKey && e.ctrlKey === ctrlKey && e.altKey === altKey && e.shiftKey === shiftKey) {
        setKeyPressed(true);
      }
    }

    const upHandler = (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [altKey, ctrlKey, shiftKey, targetKey]);
  return keyPressed;
};
