import React, { FC, memo } from 'react';
import { Grid, Hidden, makeStyles, Theme } from '@material-ui/core';
import { StructuredText } from 'components/cms';
import { ResponsiveGrid } from './Layout';
import { GlobalCmsData } from 'hooks/use-global-cms-data';
import { MONTBLANC_FONT_FAMILY, ROBOTO_FONT_FAMILY } from './Layout/theme';
import { Link, SocialLinks } from 'components';
import { pages as apptPages } from 'features/appointments/urls';
import cx from 'classnames';
import { useCanvasAvailability } from 'features/canvas/hooks';

const useStyles = makeStyles<Theme, { mode: 'dark' | 'light' }>((theme) => ({
  root: {
    width: '100%',
    backgroundColor: (props) => `${props?.mode === 'dark' ? theme.palette.Vegemite.main : theme.palette.Dark7.main}`,
    color: (props) => `${props?.mode === 'dark' ? theme.palette.Dark9.main : theme.palette.Vegemite.main}`,
    '& a': {
      color: (props) => `${props?.mode === 'dark' ? theme.palette.Dark9.main : theme.palette.Dark1.main}`,
    },
    padding: '64px 0',
  },
  anchorClass: {
    textDecoration: 'none',
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.01em',
  },
  contactSection: {
    '& p': { margin: 0 },
  },
  middleColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contactHeader: {
    color: theme.palette.Dark3.main,
    fontFamily: ROBOTO_FONT_FAMILY,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '150%',
  },
  contactHeaderMargin: {
    marginTop: '24px!important',
  },
  smallHeaderMargin: {
    marginTop: '4px!important',
  },
  openingHours: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  socialsClass: {
    marginTop: '40px',
    [theme.breakpoints.down('md')]: { marginBottom: '24px' },
  },
  gridContainer: {
    alignItems: 'stretch',
  },
  disabledLink: { opacity: 0.5, pointerEvents: 'none' },
}));

type Props = {
  mode: 'dark' | 'light';
  data: Pick<
    GlobalCmsData['globalConfiguration'],
    | 'authenticatedContentLinks'
    | 'authenticatedLegalLinks'
    | 'publicContentLinks'
    | 'publicLegalLinks'
    | 'email'
    | 'mediaEmail'
    | 'businessHours'
    | 'facebookUrl'
    | 'instagramUrl'
    | 'linkedinUrl'
    | 'phone'
  >;
  isAuthenticated: boolean;
};

const Component: FC<Props> = ({ mode, data, isAuthenticated }) => {
  const classes = useStyles({ mode: mode ?? 'light' });
  const { availability } = useCanvasAvailability();

  return (
    <div className={classes.root}>
      <ResponsiveGrid gridContainerClassName={classes.gridContainer}>
        <Hidden only={'md'}>
          <Grid item xs={12} lg={4}>
            <nav>
              <StructuredText
                data={isAuthenticated ? data.authenticatedLegalLinks : data.publicLegalLinks}
                aClassName={classes.anchorClass}
                gtmEventCategory="Footer"
              />
            </nav>
          </Grid>
          <Grid container direction="column" item xs={12} lg={4} className={classes.middleColumn}>
            <Grid item>
              <nav>
                <StructuredText
                  data={isAuthenticated ? data.authenticatedContentLinks : data.publicContentLinks}
                  aClassName={classes.anchorClass}
                  gtmEventCategory="Footer"
                />
              </nav>
            </Grid>
            <Grid item>
              <SocialLinks
                className={classes.socialsClass}
                mode={mode}
                facebookUrl={data.facebookUrl}
                linkedinUrl={data.linkedinUrl}
                instagramUrl={data.instagramUrl}
              />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden only={['xs', 'sm', 'lg', 'xl']}>
          <Grid item md={6}>
            <nav>
              <StructuredText
                data={isAuthenticated ? data.authenticatedLegalLinks : data.publicLegalLinks}
                aClassName={classes.anchorClass}
                gtmEventCategory="Footer"
              />
              <StructuredText
                data={isAuthenticated ? data.authenticatedContentLinks : data.publicContentLinks}
                aClassName={classes.anchorClass}
                gtmEventCategory="Footer"
              />
              <SocialLinks
                className={classes.socialsClass}
                mode={mode}
                facebookUrl={data.facebookUrl}
                linkedinUrl={data.linkedinUrl}
                instagramUrl={data.instagramUrl}
              />
            </nav>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6} lg={4} className={classes.contactSection}>
          <p className={classes.contactHeader}>TALK TO US</p>
          {data.businessHours.split('\n').map((value) => (
            <p className={classes.openingHours} key={value}>
              {value}
            </p>
          ))}
          <p className={cx(classes.contactHeader, classes.contactHeaderMargin)}>PHONE</p>
          <Link className={classes.anchorClass} href={`tel:${data.phone}`}>
            {data.phone}
          </Link>
          <p className={cx(classes.contactHeader, classes.smallHeaderMargin)}>OR</p>
          <Link className={classes.anchorClass} href={apptPages.NEW_APPOINTMENT}>
            Schedule a chat
          </Link>
          {availability === 'offline' && (
            <>
              <p className={cx(classes.contactHeader, classes.contactHeaderMargin)}>TRUECANVAS</p>
              <p className={cx(classes.anchorClass, classes.disabledLink)}>Chat online now</p>
            </>
          )}
          <p className={cx(classes.contactHeader, classes.contactHeaderMargin)}>EMAIL</p>
          <Link className={classes.anchorClass} href={`mailto:${data.email}`}>
            {data.email}
          </Link>
        </Grid>
      </ResponsiveGrid>
    </div>
  );
};

export const SiteMap = memo(Component);
