import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { FormMessage } from 'components';
import { ResponsiveGrid } from 'components';
import { useEffect } from 'react';
import { useSessionStorage } from 'hooks/use-session-storage';
import { useRouter } from 'next/router';

const useStyles = makeStyles(() => ({
  referral: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 80,
    zIndex: 10,
  },
  fadeOut: {
    opacity: 0,
    width: 0,
    height: 0,
    transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s',
  },
}));

const REFERRAL_BANNER_STORAGE_KEY = 'titan:referral-banner:show';

export const ReferralBanner: FC = () => {
  const classes = useStyles();
  const [fadeOut, setFadeOut] = useState(false);
  const [showBanner, setShowBanner] = useSessionStorage<boolean>(REFERRAL_BANNER_STORAGE_KEY, true);
  const router = useRouter();
  const { utm_source = null, utm_campaign = null } = router.query || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 5_000);
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        setShowBanner(false);
      }, 2_000);
    }
  }, [fadeOut, setShowBanner]);

  if (!(utm_source === 'partner' || utm_source === 'peer') || !showBanner || !utm_campaign) {
    return null;
  }

  return (
    <div
      className={cx(classes.referral, {
        [classes.fadeOut]: fadeOut,
      })}
    >
      <ResponsiveGrid>
        <FormMessage isSuccess message={`Referral applied: ${utm_campaign}`} marginTop={false} />
      </ResponsiveGrid>
    </div>
  );
};
