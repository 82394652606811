import { mutate } from 'swr';
import { apis } from '../urls';
import { SetCurrentExpertPresenceResponse, SetCurrentExpertPresenceRequest } from '../models';
import { post } from 'lib/fetcher';
import { useAsync } from 'hooks/use-async';

const setCurrentExpertPresence = async (body: SetCurrentExpertPresenceRequest) => {
  const url = apis.CURRENT_USER;
  const result = await post<SetCurrentExpertPresenceResponse>(url, body);
  mutate(url, null);
  return result;
};

export const useSetCurrentExpertPresence = () => {
  const { result, error, isExecuting, mutate } = useAsync<
    SetCurrentExpertPresenceRequest,
    SetCurrentExpertPresenceResponse
  >(setCurrentExpertPresence);
  return { expertPresence: result, isExecuting, error, setPresence: mutate };
};
