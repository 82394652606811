import useSWR from 'swr';
import { anonymousFetcher } from 'lib/fetcher';
import { apis } from '../urls';
import { Healthz } from '../models/health';

export function useHealthz() {
  const { data: healthzData, error } = useSWR<Healthz>(apis.HEALTHZ, anonymousFetcher);

  return {
    healthzData,
    error,
    isLoading: !error && !healthzData,
  };
}
